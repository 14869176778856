<template>
  <link rel='preload' as='font' src='fonts/Rotunda/3a-rotunda-light.woff2' type='font/woff2' crossorigin='anonymous'>
  <link rel='preload' as='font' src='fonts/Rotunda/3a-rotunda-light.woff' type='font/woff' crossorigin='anonymous'>

  <link rel='preload' as='font' src='fonts/Rotunda/3b-rotunda-light-italic.woff2' type='font/woff2' crossorigin='anonymous'>
  <link rel='preload' as='font' src='fonts/Rotunda/3b-rotunda-light-italic.woff' type='font/woff' crossorigin='anonymous'>
  
  <link rel='preload' as='font' src='fonts/Rotunda/4a-rotunda-regular.woff2' type='font/woff2' crossorigin='anonymous'>
  <link rel='preload' as='font' src='fonts/Rotunda/4a-rotunda-regular.woff' type='font/woff' crossorigin='anonymous'>

  <link rel='preload' as='font' src='fonts/Rotunda/4b-rotunda-regular-italic.woff2' type='font/woff2' crossorigin='anonymous'>
  <link rel='preload' as='font' src='fonts/Rotunda/4b-rotunda-regular-italic.woff' type='font/woff' crossorigin='anonymous'>

  <link rel='preload' as='font' src='fonts/Rotunda/5a-rotunda-medium.woff2' type='font/woff2' crossorigin='anonymous'>
  <link rel='preload' as='font' src='fonts/Rotunda/5a-rotunda-medium.woff' type='font/woff' crossorigin='anonymous'>

  <link rel='preload' as='font' src='fonts/Rotunda/5b-rotunda-medium-italic.woff2' type='font/woff2' crossorigin='anonymous'>
  <link rel='preload' as='font' src='fonts/Rotunda/5b-rotunda-medium-italic.woff' type='font/woff' crossorigin='anonymous'>

  <link rel='preload' as='font' src='fonts/Rotunda/7a-rotunda-extrabold.woff2' type='font/woff2' crossorigin='anonymous'>
  <link rel='preload' as='font' src='fonts/Rotunda/7a-rotunda-extrabold.woff' type='font/woff' crossorigin='anonymous'>
  
  <link rel='preload' as='font' src='fonts/Rotunda/7b-rotunda-extrabold-italic.woff2' type='font/woff2' crossorigin='anonymous'>
  <link rel='preload' as='font' src='fonts/Rotunda/7b-rotunda-extrabold-italic.woff' type='font/woff' crossorigin='anonymous'>

  <div id='NameSpan' :style="{width: holderWidth + 'px', height: 0.24*holderWidth + 'px', top: (0.95*viewHeight - 0.24*holderWidth) + 'px', left: resizePaddingWidth + 'px', fontSize: 1.375*titleTextSize + 'px'}">
    <div :style="{width: 0.926*holderWidth + 'px', height: 0.12*holderWidth + 'px', letterSpacing: 0.057*titleTextSize + 'px'}">Oldenborgh</div>
    <div :style="{width: 0.926*holderWidth + 'px', height: 0.12*holderWidth + 'px', wordSpacing: 3.44*titleTextSize + 'px'}">Leon van</div>
  </div>


  <MenuButton :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
  <SideBarMenu :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth">
      <ul class="sidebarNav" v-if="projects.length > 0">
        <li :style="{marginLeft: -0.485*titleTextSize + 'px'}" @click="closeSidebarPanel">
          <a href="#About" :style="{fontSize: 0.5*titleTextSize + 'px', marginTop: 1*titleTextSize + 'px', marginBottom: 0.25*titleTextSize + 'px'}">
          <img src='@/assets/WhiteSquare.png' :style="{width: 0.3*titleTextSize + 'px', height: 0.3*titleTextSize + 'px', marginTop: -0.05*titleTextSize + 'px', marginRight: 0.3*titleTextSize + 'px'}">
          <span>About</span></a>
        </li>
        <li :style="{marginLeft: -0.5*titleTextSize + 'px'}" @click="closeSidebarPanel">
          <a href="#Home" :style="{fontSize: 0.485*titleTextSize + 'px', marginBottom: 1*titleTextSize + 'px'}">
          <img src='@/assets/WhiteSquare.png' :style="{width: 0.3*titleTextSize + 'px', height: 0.3*titleTextSize + 'px', marginTop: -0.05*titleTextSize + 'px', marginRight: 0.3*titleTextSize + 'px'}">
          <span>Home</span></a>
        </li>
        <li v-for="(item, index) in projects" :key="item" :style="{marginLeft: -0.485*titleTextSize + 'px'}" @click="closeSidebarPanel">
          <a :href="'#' + projectIDs[index]" :style="{fontSize: 0.5*titleTextSize + 'px', marginBottom: 0.25*titleTextSize + 'px'}">
          <img src='@/assets/WhiteSquare.png' :style="{width: 0.3*titleTextSize + 'px', height: 0.3*titleTextSize + 'px', marginTop: -0.05*titleTextSize + 'px', marginRight: 0.3*titleTextSize + 'px'}">
          <span>{{item.title}}</span></a>
        </li>
     </ul>
  </SideBarMenu>

  <div id='Main' v-if="projects.length > 0" class='snappingHolder' ref='scrollHolder' v-on:scroll="referenceAllVideoElements">
    <AboutPage class='snapping' id="About" :view-width="viewWidth" :inspirations="inspirations" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
    <LandingPage  ref="home" class='snapping' id="Home" :projects="projects" :inspirations="inspirations" :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
    <template v-for="(item, index) in projects" :key="item">
      <template v-if="index !== 0">
        <YearChange v-if="item.yearOfPublication !== projects[index-1].yearOfPublication" :year="item.yearOfPublication" :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
      </template>
      <template v-if="index == 0">
        <YearChange :year="item.yearOfPublication" :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
      </template>
      <ProjectSingle class='snapping' :id=projectIDs[index] :project="item" :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
    </template>
    <EndOfPage :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
  </div>

</template>

<script>
import { createClient } from 'contentful'
import { VueMeta } from 'vue-meta';

const client = createClient({
  space: '0c10cja7k02u',
  accessToken: 'VFm5Fs-XEZAJKa0NzEM6HYjnly3a5k-q3OXXttRmmtc',
});

import LandingPage from '@/components/LandingPage.vue'
import ProjectSingle from '@/components/ProjectSingle.vue';
import EndOfPage from '@/components/EndOfPage.vue';
import YearChange from '@/components/YearChange.vue';
import AboutPage from '@/components/AboutPage.vue';
import MenuButton from '@/components/MenuButton.vue';
import SideBarMenu from '@/components/SideBarMenu.vue';

export default {
  name: 'App',
  metaInfo: {
    title: 'Portfolio of Leon van Oldenborgh',
    content: '🟥 On this website I share a selection of projects that I have made or have been a part of. 🟥 Feel free to take a look around and discover what my practice is all about. 🟥'
  },
  components: {
    LandingPage,
    ProjectSingle,
    EndOfPage,
    YearChange,
    AboutPage,
    MenuButton,
    SideBarMenu,
  },

  data(){
    return{
	    projects: [],
      projectIDs: [],
      projectYears: [],
      inspirations: [],
      videos: [],
      viewWidth: 1080,
      viewHeight: 1920,
      aspectRatio: 0.5625,
      expectedAspectRatio: 0.5625,
      resizePaddingWidth: 0,
      holderWidth: 540,
      titleTextSize: 0,
      resizePaddingWidth: 0,
    }
  },

  created(){
    document.title = 'Leon van Oldenborgh'

    //import project collection
    client.getEntry('563QTh9inCTG61OhPSKub4', {include:10}).then(entry => {
      console.log(entry);
      console.log('---Imported projects:---')
      for(let project of entry.fields.mainCollection){
        this.projects.push(project.fields); 

        this.projectIDs.push(project.fields.title.replaceAll(/\s/g,''));  

        console.log(project.fields.title);    
      }
      console.log('------------------------')
      console.log(this.projectIDs);
    }).catch(err => console.error(err));

    //import inspiration text
    client.getEntry('7JJ52XIaFbexp2kvhEqI9u', {include:10}).then(entry => {
      console.log(entry);
      console.log('---Inspirations:---')
      for(let item of entry.fields.inspiration){
        this.inspirations.push(item);       
        console.log(item);    
      }
      console.log('------------------------')
    }).catch(err => console.error(err));

    //Resize event
    window.addEventListener("resize", this.resizeEventHandler);
    //Scroll event
    //window.addEventListener("scroll", this.scrollEventHandler);

    this.viewWidth = visualViewport.width;
    this.viewHeight = visualViewport.height;
    this.aspectRatio = visualViewport.width / visualViewport.height;

    this.holderWidth = Math.min(this.viewHeight*this.expectedAspectRatio, this.viewWidth);
    this.resizePaddingWidth = 0.5 * (this.viewWidth - this.holderWidth);
    this.titleTextSize = this.holderWidth*0.09;
  },

  destroyed() {
    window.removeEventListener("resize", this.resizeEventHandler);
    //window.removeEventListener("scroll", this.scrollEventHandler);
  },

  updated(){
    this.referenceAllVideoElements();
  },

  methods: {
    resizeEventHandler(e) {
      this.viewWidth = visualViewport.width;
      this.viewHeight = visualViewport.height;
      this.aspectRatio = visualViewport.width / visualViewport.height;

      this.holderWidth = Math.min(this.viewHeight*this.expectedAspectRatio, this.viewWidth);
      this.resizePaddingWidth = 0.5 * (this.viewWidth - this.holderWidth);
      this.titleTextSize = this.holderWidth*0.09;
    },

    //scrollEventHandler(e){
      //this.referenceAllVideoElements();
    //},

    referenceAllVideoElements(){
      let currentVideos = document.querySelectorAll("video");
      currentVideos.forEach((video) => {
        video.muted = true;
      });

      if(currentVideos.length == this.videos.length){return;}

      currentVideos.forEach((video) => {
        if(!this.videos.includes(video)){
          this.playPauseVideo(video)
          this.videos.push(video)
        }
      });
    },

    playPauseVideo(video){
      let playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise.then((_) => {
                let observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (
                                entry.intersectionRatio !== 1 &&
                                !video.paused
                            ) {
                                console.log('video is pauzed' + video);
                                video.pause();
                            } else if (video.paused) {
                                console.log('video is played' + video);
                                video.play();
                            }
                        });
                    },
                    { threshold: 0.2 }
                    );
        observer.observe(video);
      })
      }
    },

    playPauseVideos() {
      let curVideos = document.querySelectorAll("video");
      curVideos.forEach((video) => {
        let playPromise = video.play();
        if (playPromise !== undefined) {
            playPromise.then((_) => {
                let observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (
                                entry.intersectionRatio !== 1 &&
                                !video.paused
                            ) {
                                console.log('video is pauzed' + video);
                                video.pause();
                            } else if (video.paused) {
                                console.log('video is played' + video);
                                video.play();
                            }
                        });
                    },
                    { threshold: 0.2 }
                );
                observer.observe(video);
            });
        }
    });
    this.videos = curVideos;
    },

    closeSidebarPanel(){
      this.$store.commit('toggle');                
    },
  }
}
</script>

<style>
  html,body{
    margin: 0;
    padding: 0;
  }
  
  #app {
    font-family: Rotunda, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #1F1F1C;
    font-weight: 300;
    font-style: normal;
    font-size: 100%;
    text-size-adjust: none;
    font-optical-sizing: none;
    font-stretch: 100%;
    font-synthesis: none;
    text-rendering: geometricPrecision;
  }
  
  #NameSpan{
    pointer-events: none;
    display: flex;
    align-items: center;
    flex-flow: column-reverse nowrap;
    bottom: 7.5vh;
    text-transform: uppercase;
    text-align: left;
    position: fixed;
    z-index: 500;
  }

  .snappingHolder{
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scrollbar-color: #E52C3A;
  }

  .snapping{
    scroll-snap-align: start;
  }

  ul.sidebarNav{
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  ul.sidebarNav li a{
    color: #E52C3A;
    text-decoration: none;
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    text-align: left;
    padding: 0;
    margin: 0;
  }

  ul.sidebarNav li a:hover{
    color: #FFFFFF;
    background-color: #E52C3A;
  }

  ul.sidebarNav li a img{
    display: inline-block;
    align-self: center;
    padding: 0;
  }

@font-face {
  font-family: 'Rotunda';
  font-display: auto;
  font-weight: 300;
  font-style: normal;
  src: 
    local('Rotunda'), 
    url('fonts/Rotunda/3a-rotunda-light.woff2') format('woff2'),
    url('fonts/Rotunda/3a-rotunda-light.woff') format('woff');
}

@font-face {
  font-family: 'Rotunda';
  font-display: auto;
  font-weight: 300;
  font-style: italic;
  src: 
    local('Rotunda'), 
    url('fonts/Rotunda/3b-rotunda-light-italic.woff2') format('woff2'),
    url('fonts/Rotunda/3b-rotunda-light-italic.woff') format('woff');
}

@font-face {
  font-family: 'Rotunda';
  font-display: auto;
  font-weight: 400;
  font-style: normal;
  src: 
    local('Rotunda'), 
    url('fonts/Rotunda/4a-rotunda-regular.woff2') format('woff2'),
    url('fonts/Rotunda/4a-rotunda-regular.woff') format('woff');
}

@font-face {
  font-family: 'Rotunda';
  font-display: auto;
  font-weight: 400;
  font-style: italic;
  src: 
    local('Rotunda'), 
    url('fonts/Rotunda/4b-rotunda-regular-italic.woff2') format('woff2'),
    url('fonts/Rotunda/4b-rotunda-regular-italic.woff') format('woff');
}

@font-face {
  font-family: 'Rotunda';
  font-display: auto;
  font-weight: 500;
  font-style: normal;
  src: 
    local('Rotunda'), 
    url('fonts/Rotunda/5a-rotunda-medium.woff2') format('woff2'),
    url('fonts/Rotunda/5a-rotunda-medium.woff') format('woff');
}

@font-face {
  font-family: 'Rotunda';
  font-display: auto;
  font-weight: 500;
  font-style: italic;
  src: 
    local('Rotunda'), 
    url('fonts/Rotunda/5b-rotunda-medium-italic.woff2') format('woff2'),
    url('fonts/Rotunda/5b-rotunda-medium-italic.woff') format('woff');
}

@font-face {
  font-family: 'Rotunda';
  font-display: auto;
  font-weight: 700;
  font-style: normal;
  src: 
    local('Rotunda'), 
    url('fonts/Rotunda/7a-rotunda-extrabold.woff2') format('woff2'),
    url('fonts/Rotunda/7a-rotunda-extrabold.woff') format('woff');
}

@font-face {
  font-family: 'Rotunda';
  font-display: auto;
  font-weight: 700;
  font-style: italic;
  src: 
    local('Rotunda'), 
    url('fonts/Rotunda/7b-rotunda-extrabold-italic.woff2') format('woff2'),
    url('fonts/Rotunda/7b-rotunda-extrabold-italic.woff') format('woff');
}

</style>
