<template>
<div :id='project.title' class='baseHolder' v-if="project !== undefined">
  <div class='resizePadding MainHolder' :style="{width: resizePaddingWidth + 'px'}">
  </div>

  <div id='HolderLeft' class='MainHolder' :style="{width: holderWidth*0.44 + 'px'}">

    <div id='SquarePreviewHolder' :style="{width: holderWidth*0.44 + 'px', height: holderWidth*0.44 + 'px', marginLeft: -displacementFactor*(0.2)*resizePaddingWidth + 'px'}">
        <img :src="project.squarePreview.fields.file.url">
    </div>

    <div id='TaglineHolder' :style="{height: 0.08*holderWidth + 'px', width: resizePaddingWidth + holderWidth*0.44 + 'px', marginLeft: -resizePaddingWidth + 'px'}">
        <ProjectTagline :tagline="project.tagline" :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
    </div>

    <div id='DescriptionHolder' :style="{height: (1.735-0.44-0.08-0.10-0.26-0.14)*holderWidth + 'px', marginLeft: -displacementFactor*(0.15)*resizePaddingWidth + 'px'}">
        <ProjectDescription :description="project.description" :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
    </div>

    <div id='CollaboratorsHolder' :style="{height: 0.10*holderWidth + 'px', width: resizePaddingWidth + holderWidth*0.44 + 'px', marginLeft: -resizePaddingWidth + 'px'}">
        <ProjectCollaborators :collaborator-name="project.collaborators" :collaborator-url="project.collaboratorUrLs" :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
    </div>

  </div>

  <div id='HolderMiddle' class='MainHolder' :style="{width: holderWidth*0.19 + 'px'}">

    <div id='TitleHolder' :style="{height: (1.735-0.185-0.275)*holderWidth + 'px'}">
        <h1 :style="{paddingRight: 0.009*holderWidth + 'px', marginTop: 0.03*viewHeight + 'px', fontSize: titleTextSize + 'px', letterSpaing: 0.3*titleTextSize + 'px', wordSpacing: 0.2*titleTextSize + 'px'}">{{ project.title }}</h1>
    </div>

    <div id='RoleHolder' :style="{height: 0.3*holderWidth + 'px'}">
        <ProjectRoles :roles="project.ownRoles" :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
    </div>

  </div>

  <div id='HolderRight' class='MainHolder' :style="{width: holderWidth*0.37 + 'px'}">

    <div id='DateHolder' :style="{width: resizePaddingWidth + holderWidth*0.37 + 'px'}">
        <ProjectDate :publicationMonth="project.monthOfPublication" :runtime="project.projectRuntime" :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
    </div>

    <div id='PlatformHolder' :style="{width: resizePaddingWidth + holderWidth*0.37 + 'px', height: 0.235*holderWidth + 'px'}">
        <ProjectPlatform :platforms="project.platform" :platform-links="project.platformUrLs" :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
    </div>

    <div id='NuancedPreviewHolder' :style="{width: holderWidth*0.37 + displacementFactor*(0.5)*resizePaddingWidth + (0.3)*resizePaddingWidth + 'px', height: (1.735-0.235-0.35)*holderWidth - 0.2*viewHeight + 'px'}">
        <!--<img :src="project.nuancedPreview.fields.file.url">-->
        <ProjectNuancedPreview :previewFiles="project.nuancedVideo" :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
    </div>

  </div>

  <div class='resizePadding MainHolder' :style="{width: resizePaddingWidth + 'px'}">
  </div>
</div>
</template>

<script>
import ProjectRoles from '@/components/ProjectComponents/ProjectRoles.vue';
import ProjectTagline from '@/components/ProjectComponents/ProjectTagline.vue';
import ProjectDescription from '@/components/ProjectComponents/ProjectDescription.vue';
import ProjectCollaborators from '@/components/ProjectComponents/ProjectCollaborators.vue';
import ProjectDate from '@/components/ProjectComponents/ProjectDate.vue';
import ProjectPlatform from '@/components/ProjectComponents/ProjectPlatform.vue';
import ProjectNuancedPreview from '@/components/ProjectComponents/ProjectNuancedPreview.vue';

export default {
    name: 'ProjectSingle',
    components:{
        ProjectRoles,
        ProjectTagline,
        ProjectDescription,
        ProjectCollaborators,
        ProjectDate,
        ProjectPlatform,
        ProjectNuancedPreview
    },

    data(){
        return{
            displacementFactor: 0,
        }
    },

    props:{
        project: Object,
        viewWidth: Number,
        viewHeight: Number,
        holderWidth: Number,
        titleTextSize: Number,
        resizePaddingWidth: Number,
    },

    created(){
        this.displacementFactor = Math.random();
        this.displacementFactor = 0;
    }
}
</script>

<style scoped>

.baseHolder{
    display: flex;
    flex-flow: row;
}

.MainHolder{
    margin: 0;
    padding: 0;
    height: 97.5vh;
    flex-shrink: 0;
}

.resizePadding{
    flex-shrink: 0;
}

/* Holder Left Content */

/* Square Preview Image*/

#HolderLeft #SquarePreviewHolder img{
    width: 100%;
    height: 100%;
}

/* Tagline Marquee*/
#HolderLeft #TaglineHolder{
    /* background: peru; */
    width: 100%;
    display: flex;
}

/* Description */
#HolderLeft #DescriptionHolder{
    /* background: honeydew; */
    width: 100%;
    overflow: hidden;
}

/* Collaborators */
#HolderLeft #CollaboratorsHolder{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
}

#HolderMiddle{
    background-color: #E52C3A;
    display: flex;
    flex-flow: column nowrap;
}



/* Holder Middle Content */

/* Project Title */
#HolderMiddle #TitleHolder{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    flex-shrink: 0;
}

#HolderMiddle h1{
    color: #FFFFFF;
    text-align: center;
    align-self: center;
    text-transform: uppercase;
    writing-mode: vertical-lr;
    font-weight: 700;
}

/* Project Roles */
#HolderMiddle #RoleHolder{
    display: flex;
    flex-flow: column nowrap;
}


/* Holder Right Content */

/* Date info */
#HolderRight #DateHolder{
    width: 100%;
    height: 20vh;
}

/* Platform links */
#HolderRight #PlatformHolder{
    display: flex;
    flex-flow: column nowrap;
    align-content: center;
    justify-content: center;
    width: 100%
}

/* Nuanced Preview Holder*/
#HolderRight #NuancedPreviewHolder{
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
}
</style>