<template>
    <div class="sidebar">
        <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
        <transition name="slide">
            <div v-if="isPanelOpen" class="sidebar-panel" :style="{width: (0.44+0.19)*holderWidth + 'px'}">
                <!--<div class="menuPadding" :style="{width: resizePaddingWidth + 'px'}">
                </div>-->
                <div class="menuPadding" :style="{width: (0.19)*holderWidth + 'px'}">
                </div>
                <div class="menuTextHolder" :style="{width: (0.44-(0.19/4))*holderWidth + 'px', height: 1.00*viewHeight + 'px'}">
                    <slot></slot>
                </div>
                <div class="menuPadding" :style="{width: (0.19/4)*holderWidth + 'px'}">
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'SideBarMenu',

        props:{
            viewWidth: Number,
            viewHeight: Number,
            holderWidth: Number,
            titleTextSize: Number,
            resizePaddingWidth: Number,
        },

        methods: {
            closeSidebarPanel(){
                this.$store.commit('toggle');                
            },
        },

        computed: {
            isPanelOpen() {
                return this.$store.state.isNavOpen;
            }
        }
    }
</script>

<style>
    .slide-enter-active,
    .slide-leave-active
    {
        transition: transform 0.5s ease;
    }

    .slide-enter-from,
    .slide-leave-to {
        transform: translateX(-100%);
        transition: all 500ms ease-in 0s
    }

    .sidebar-backdrop {
        background-color: rgba(229, 44, 58, 0.1);
        z-index: 550;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
    }

    .sidebar-panel {
        overflow-y: auto;
        background-color: #E52C3A;
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        z-index: 600;
        padding: 0;  
        display: flex;
        flex-flow: row nowrap; 
    }

    .menuPadding{
        background-color: #E52C3A;
        height: 100%;
    }

    .menuTextHolder{
        background-color: #FFFFFF;
    }
</style>