<template>
<div id='about' class='baseHolder'>
  <div class='resizePadding MainHolder' :style="{width: resizePaddingWidth + 'px'}">
  </div>

  <div id='HolderLeft' class='MainHolder' :style="{width: holderWidth*0.44 + 'px'}">
    <div :style="{height: holderWidth*0.11 + 'px', marginTop: holderWidth*0.06 + 'px', marginLeft: -resizePaddingWidth + 'px'}">
        <img :src='redManicule' class='maniculeImg' :style="{width: titleTextSize*0.75 + 'px', height: titleTextSize*0.75 + 'px', marginLeft: 0.1*titleTextSize + 'px', marginRight: holderWidth*0.015 + 'px', marginBottom: titleTextSize*-0.04 + 'px'}">
        <span :style="{fontSize: titleTextSize*0.25 + 'px', marginTop: 0.04*titleTextSize + 'px'}">I have new square imagery in need of being seen.</span>
        <img src='@/assets/RedSquare.svg' :style="{width: 0.3*titleTextSize + 'px', height: 0.3*titleTextSize + 'px', marginLeft: 0.15*titleTextSize + 'px', marginRight: 0.1*titleTextSize + 'px', marginBottom: -0.01*titleTextSize + 'px'}">
    </div>
    <div :style="{height: holderWidth*0.11 + 'px', marginTop: holderWidth*0.04 + 'px', marginLeft: -resizePaddingWidth + 'px'}">
        <img :src='redManicule' class='maniculeImg' :style="{width: titleTextSize*0.75 + 'px', height: titleTextSize*0.75 + 'px', marginLeft: 0.1*titleTextSize + 'px', marginRight: holderWidth*0.015 + 'px', marginBottom: titleTextSize*-0.04 + 'px'}">
        <span :style="{fontSize: titleTextSize*0.25 + 'px', marginTop: 0.04*titleTextSize + 'px'}">I have some thoughts and updates on my work.</span>
        <img src='@/assets/RedSquare.svg' :style="{width: 0.3*titleTextSize + 'px', height: 0.3*titleTextSize + 'px', marginLeft: 0.15*titleTextSize + 'px', marginRight: 0.1*titleTextSize + 'px', marginBottom: -0.01*titleTextSize + 'px'}">
    </div>
    <div :style="{height: holderWidth*0.11 + 'px', marginTop: holderWidth*0.04 + 'px', marginLeft: -resizePaddingWidth + 'px'}">
        <img :src='redManicule' class='maniculeImg' :style="{width: titleTextSize*0.75 + 'px', height: titleTextSize*0.75 + 'px', marginLeft: 0.1*titleTextSize + 'px', marginRight: holderWidth*0.015 + 'px', marginBottom: titleTextSize*-0.04 + 'px'}">
        <span :style="{fontSize: titleTextSize*0.25 + 'px', marginTop: 0.04*titleTextSize + 'px'}">I still don’t really know what to post on here to be honest. Connect anyway?</span>
        <img src='@/assets/RedSquare.svg' :style="{width: 0.3*titleTextSize + 'px', height: 0.3*titleTextSize + 'px', marginLeft: 0.15*titleTextSize + 'px', marginRight: 0.1*titleTextSize + 'px', marginBottom: -0.01*titleTextSize + 'px'}">
    </div>
    <div :style="{height: holderWidth*0.11 + 'px', marginTop: holderWidth*0.04 + 'px', marginLeft: -resizePaddingWidth + 'px'}">
        <img :src='redManicule' class='maniculeImg' :style="{width: titleTextSize*0.75 + 'px', height: titleTextSize*0.75 + 'px', marginLeft: 0.1*titleTextSize + 'px', marginRight: holderWidth*0.015 + 'px', marginBottom: titleTextSize*-0.04 + 'px'}">
        <span :style="{fontSize: titleTextSize*0.25 + 'px', marginTop: 0.04*titleTextSize + 'px'}">All my games live here rent free, go play them!</span>
        <img src='@/assets/RedSquare.svg' :style="{width: 0.3*titleTextSize + 'px', height: 0.3*titleTextSize + 'px', marginLeft: 0.15*titleTextSize + 'px', marginRight: 0.1*titleTextSize + 'px', marginBottom: -0.01*titleTextSize + 'px'}">
    </div>
    <div :style="{height: holderWidth*0.11 + 'px', marginTop: holderWidth*0.04 + 'px', marginLeft: -resizePaddingWidth + 'px'}">
        <img :src='redManicule' class='maniculeImg' :style="{width: titleTextSize*0.75 + 'px', height: titleTextSize*0.75 + 'px', marginLeft: 0.1*titleTextSize + 'px', marginRight: holderWidth*0.015 + 'px', marginBottom: titleTextSize*-0.04 + 'px'}">
        <span :style="{fontSize: titleTextSize*0.25 + 'px', marginTop: 0.04*titleTextSize + 'px'}">My skills and experience in a coherent format.</span>
        <img src='@/assets/RedSquare.svg' :style="{width: 0.3*titleTextSize + 'px', height: 0.3*titleTextSize + 'px', marginLeft: 0.15*titleTextSize + 'px', marginRight: 0.1*titleTextSize + 'px', marginBottom: -0.01*titleTextSize + 'px'}">
    </div>
    <div :style="{height: holderWidth*0.11 + 'px', marginTop: holderWidth*0.04 + 'px', marginLeft: -resizePaddingWidth + 'px', marginBottom: holderWidth*0.02 + 'px'}">
        <img :src='redManicule' class='maniculeImg' :style="{width: titleTextSize*0.75 + 'px', height: titleTextSize*0.75 + 'px', marginLeft: 0.1*titleTextSize + 'px', marginRight: holderWidth*0.015 + 'px', marginBottom: titleTextSize*-0.04 + 'px'}">
        <span :style="{fontSize: titleTextSize*0.25 + 'px', marginTop: 0.04*titleTextSize + 'px'}">I’d love to talk with you about a project, design, art, games or something else.</span>
        <img src='@/assets/RedSquare.svg' :style="{width: 0.3*titleTextSize + 'px', height: 0.3*titleTextSize + 'px', marginLeft: 0.15*titleTextSize + 'px', marginRight: 0.1*titleTextSize + 'px', marginBottom: -0.01*titleTextSize + 'px'}">
    </div>
    <img src='@/assets/LeonPortrait.jpg' alt='Main portrait of Leon van Oldenborgh drinking some coffee outside out of a cup with the text "Delivering the Future" on it' :style="{width: holderWidth*(0.44+0.19) + 'px', marginTop: holderWidth*(0.04) + 'px'}"> 
  </div>

  <div id='HolderMiddle' class='MainHolder' :style="{width: holderWidth*0.19 + 'px'}">
        <a href='https://www.instagram.com/leonvoldenborgh/' target='_blank' class="platformLink" :style="{width: holderWidth*0.11 + 'px', height: holderWidth*0.11 + 'px', marginTop: holderWidth*0.06 + 'px'}">
            <img :src='iconsWhite[0]'>
        </a>
        <a href='https://twitter.com/leonVoldenborgh/' target='_blank' class="platformLink" :style="{width: holderWidth*0.11 + 'px', height: holderWidth*0.11 + 'px', marginTop: holderWidth*0.04 + 'px'}">
            <img :src='iconsWhite[1]'>
        </a>
        <a href='https://www.linkedin.com/in/leon-van-oldenborgh-a59234155/' target='_blank' class="platformLink" :style="{width: holderWidth*0.11 + 'px', height: holderWidth*0.11 + 'px', marginTop: holderWidth*0.04 + 'px'}">
            <img :src='iconsWhite[2]'>
        </a>
        <a href='https://leonvo.itch.io/' target='_blank' class="platformLink" :style="{width: holderWidth*0.11 + 'px', height: holderWidth*0.11 + 'px', marginTop: holderWidth*0.04 + 'px'}">
            <img :src='iconsWhite[3]'>
        </a>
        <a href='https://leonvanoldenborgh.nl/CV_LeonVanOldenborgh_Censored.pdf' target='_blank' class="platformLink" :style="{width: holderWidth*0.11 + 'px', height: holderWidth*0.11 + 'px', marginTop: holderWidth*0.04 + 'px'}">
            <img :src='iconsWhite[4]'>
        </a>
        <a href='mailto:hey@leonvanoldenborgh.nl' class="platformLink" :style="{width: holderWidth*0.11 + 'px', height: holderWidth*0.11 + 'px', marginTop: holderWidth*0.04 + 'px', marginBottom: holderWidth*0.06 + 'px'}">
            <img :src='iconsWhite[5]'>
        </a>
  </div>

  <div id='HolderRight' class='MainHolder' :style="{width: holderWidth*0.37 + 'px'}">
    <img src='@/assets/LeonPortrait1.jpg' alt='Supporting full body picture of Leon van Oldenborgh standing on an old railway that was turned into a park in Utrecht' :style="{width: holderWidth*0.37 + 'px'}">
    <div>
        <p :style="{fontSize: titleTextSize*0.25 + 'px', lineHeight: titleTextSize*0.325 + 'px'}">
            <img src='@/assets/RedSquare.svg' :style="{width: 0.2*titleTextSize + 'px', 
                height: 0.2*titleTextSize + 'px', 
                marginLeft: 0.2*titleTextSize + 'px', 
                marginRight: 0.2*titleTextSize + 'px', 
                marginBottom: -0.01*titleTextSize + 'px'}">
            Hi! I’m Leon van Oldenborgh.
            <img src='@/assets/RedSquare.svg' :style="{width: 0.2*titleTextSize + 'px', 
                height: 0.2*titleTextSize + 'px', 
                marginLeft: 0.2*titleTextSize + 'px', 
                marginRight: 0.2*titleTextSize + 'px', 
                marginBottom: -0.01*titleTextSize + 'px'}">
            I’m an artist and game designer based in the Netherlands.
            <img src='@/assets/RedSquare.svg' :style="{width: 0.2*titleTextSize + 'px', 
                height: 0.2*titleTextSize + 'px', 
                marginLeft: 0.2*titleTextSize + 'px', 
                marginRight: 0.2*titleTextSize + 'px', 
                marginBottom: -0.01*titleTextSize + 'px'}">
            I have over four years of experience creating all kinds of memorable experiences through my expertise in designing and implementing User Interactions.
            <img src='@/assets/RedSquare.svg' :style="{width: 0.2*titleTextSize + 'px', 
                height: 0.2*titleTextSize + 'px', 
                marginLeft: 0.2*titleTextSize + 'px', 
                marginRight: 0.2*titleTextSize + 'px', 
                marginBottom: -0.01*titleTextSize + 'px'}">
            Right now I’m creating art games for expositions and at home play that invoke the player to think about human behaviour and connection.            
            <img src='@/assets/RedSquare.svg' :style="{width: 0.2*titleTextSize + 'px', 
                height: 0.2*titleTextSize + 'px', 
                marginLeft: 0.2*titleTextSize + 'px', 
                marginRight: 0.2*titleTextSize + 'px', 
                marginBottom: -0.01*titleTextSize + 'px'}">
        </p>
        <LandingInspire v-if="inspirations.length > 0" :inspirations="inspirations" :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
    </div>    
  </div>

  <div class='resizePadding MainHolder' :style="{width: resizePaddingWidth + 'px'}">
  </div>
</div>
</template>

<script>
    //Red
    import instagramIconRed from '@/assets/SocialsIcons/InstagramIcon-Red.svg'
    import twitterIconRed from '@/assets/SocialsIcons/TwitterIcon-Red.svg'
    import linkedInIconRed from '@/assets/SocialsIcons/LinkedInIcon-Red.svg'
    import itchIconRed from '@/assets/SocialsIcons/ItchIcon-Red.svg'
    import cvIconRed from '@/assets/SocialsIcons/CvIcon-Red.svg'
    import mailIconRed from '@/assets/SocialsIcons/MailIcon-Red.svg'
    //white
    import instagramIconWhite from '@/assets/SocialsIcons/InstagramIcon-White.svg'
    import twitterIconWhite from '@/assets/SocialsIcons/TwitterIcon-White.svg'
    import linkedInIconWhite from '@/assets/SocialsIcons/LinkedInIcon-White.svg'
    import itchIconWhite from '@/assets/SocialsIcons/ItchIcon-White.svg'
    import cvIconWhite from '@/assets/SocialsIcons/CvIcon-White.svg'
    import mailIconWhite from '@/assets/SocialsIcons/MailIcon-White.svg'

    //Manicules
    import maniculeRed from '@/assets/WindowsDefaultManicule-red.svg'
    import maniculeWhite from '@/assets/WindowsDefaultManicule-white.svg'

    import LandingInspire from '@/components/LandingComponents/LandingInspire.vue';

export default {
    name: 'AboutPage',

    components:{
        LandingInspire,
    },

        props: {
            inspirations: [String],
            viewWidth: Number,
            viewHeight: Number,
            holderWidth: Number,
            titleTextSize: Number,
            resizePaddingWidth: Number,
        },

    data(){
        return{
            iconsWhite: [instagramIconWhite, twitterIconWhite, linkedInIconWhite, itchIconWhite, cvIconWhite, mailIconWhite],
            iconsRed: [instagramIconRed, twitterIconRed, linkedInIconRed, itchIconRed, cvIconRed, mailIconRed],

            redManicule: maniculeRed,
            whiteManicule: maniculeWhite,
        }
    },

}
</script>

<style scoped>

.baseHolder{
    display: flex;
    flex-flow: row;
}

.MainHolder{
    margin: 0;
    padding: 0;
    height: 95vh;
    flex-shrink: 0;
}

.resizePadding{
    flex-shrink: 0;
}

#HolderLeft{
    display: flex;
    flex-flow: column nowrap;
}

#HolderLeft div{
    display: flex;
    flex-flow: row-reverse nowrap;
}

#HolderLeft img{
    z-index: 100;
}

#HolderLeft div img{
    align-self: center;
}

#HolderLeft div span{
    align-self: center;
    text-align: justify;
}

#HolderLeft div .manicule{
    color:#E52C3A;
}

#HolderLeft div .maniculeImg{
    align-self: center;

    -webkit-transform:rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

#HolderMiddle{
    background-color: #E52C3A;
    display: flex;
    flex-flow: column nowrap;
}

.platformLink{
    background-color: #FFFFFF;
    align-self: center;
    margin: 0;
    padding: 0;
}

a img{
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

a:hover img{
    margin: 7.5%;
    height: 85%;
    width: 85%;
}

#HolderRight p{
    color: #1F1F1C;
    text-align: left;
    letter-spacing: initial;
    flex-shrink: 2;

    padding: 10% 7.5% 0 10%; /* Randomise first value between right value and (idk what the max is)*/
    margin: 0;
    align-self: center;
}

</style>