<template>
    <p v-if="description !== undefined" :style="{fontSize: 0.25*titleTextSize + 'px', lineHeight: 0.33*titleTextSize + 'px'}">
        <template v-for="(sentence) in splitDescription" :key="sentence">
            <img src='@/assets/RedSquare.svg' :style="{width: 0.2*titleTextSize + 'px', height: 0.2*titleTextSize + 'px', marginLeft: 0.2*titleTextSize + 'px', marginRight: 0.2*titleTextSize + 'px', marginBottom: -0.01*titleTextSize + 'px'}"> {{ sentence }}.
        </template>
        <img src='@/assets/RedSquare.svg' :style="{width: 0.2*titleTextSize + 'px', height: 0.2*titleTextSize + 'px', marginLeft: 0.2*titleTextSize + 'px', marginRight: 0.2*titleTextSize + 'px', marginBottom: -0.01*titleTextSize + 'px'}">
    </p>
</template>

<script>

export default {
    name: 'ProjectDescription',

    data() {
        return{
            formattedDescription: '',
            splitDescription: [String],
        }
    },

    props:{
        description: String,
        viewWidth: Number,
        viewHeight: Number,
        holderWidth: Number,
        titleTextSize: Number,
        resizePaddingWidth: Number,
        displacement: Number,
    },

    mounted(){
        this.AddSquares();
    },

    methods:{
        AddSquares(){
            this.splitDescription = this.description.split('.');
            this.splitDescription.pop();
        }
    }
}
</script>

<style scoped>
p{
    color: #1F1F1C;
    text-align: left;
    letter-spacing: initial;

    padding: 15% 10% 15% 15%; /* Randomise first value between right value and (idk what the max is)*/
    margin: 0 0 0 0;
}

p img{
    padding: 0 0 0 0;
    display: inline-block;
}
</style>