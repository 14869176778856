<template>
<div id='BaseHolder'>
  <div class='resizePadding MainHolder' :style="{width: resizePaddingWidth + 'px', height: holderWidth*0.3 + 'px'}">
  </div>

  <div id='HolderLeft' class='MainHolder' :style="{width: holderWidth*0.44 + 'px', height: holderWidth*0.3 + 'px'}">
  </div>

  <div id='HolderMiddle' class='MainHolder' :style="{width: holderWidth*0.19 + 'px', height: holderWidth*0.3 + 'px'}">
    <div class="nonSeperator" :style="{height: holderWidth*0.025 + 'px'}"/>
    <div class="seperator" :style="{height: holderWidth*0.04 + 'px'}"/>
    <div class="nonSeperator" :style="{height: holderWidth*0.19 + 'px'}"/>
    <div class="seperator" :style="{height: holderWidth*0.04 + 'px'}"/>
    <div class="nonSeperator" :style="{height: holderWidth*0.025 + 'px'}"/>
  </div>

  <div id='HolderRight' class='MainHolder' :style="{width: holderWidth*0.37 + 'px', height: holderWidth*0.3 + 'px'}">
    <span :style="{fontSize: titleTextSize + 'px', marginLeft: titleTextSize + 'px'}">{{year}}</span>
  </div>

  <div class='resizePadding MainHolder' :style="{width: resizePaddingWidth + 'px', height: holderWidth*0.3 + 'px'}">
  </div>
</div>
</template>

<script>
export default {
    name: 'YearChange',
    props: {
        year: Number,
        viewWidth: Number,
        viewHeight: Number,
        holderWidth: Number,
        titleTextSize: Number,
        resizePaddingWidth: Number,
    }
}
</script>

<style scoped>

#BaseHolder{
    display: flex;
    flex-flow: row;
}

.MainHolder{
    margin: 0;
    padding: 0;
    flex-shrink: 0;
}

.resizePadding{
    flex-shrink: 0;
}

#HolderMiddle{
    display: flex;
    flex-flow: column nowrap;
}

#HolderRight{
    display: flex;
    flex-flow: row nowrap;
}

span{
    align-self: center;
    text-align: left;
    color: #E52C3A;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.seperator{
    background-color: #FFFFFF;
}

.nonSeperator{
    background-color: #E52C3A;
}

</style>