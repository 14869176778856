<template>
    <div id='PlayTitleContainer' :style="{height: 0.075*holderWidth + 'px'}">
        <span :style="{fontSize: 0.6*titleTextSize + 'px', lineHeight: 0.75*titleTextSize + 'px', letterSpacing: 0.015*titleTextSize + 'px', marginLeft: -3*holderWidth + 'px', marginRight: -3*holderWidth + 'px'}">
             <img :src='redManicule' class='maniculeImg flip' :style="{width: titleTextSize*0.6 + 'px', height: titleTextSize*0.6 + 'px', marginRight: titleTextSize*-0.14 + 'px', marginBottom: titleTextSize*-0.11 + 'px'}">
             {{actionDisplay}} 
             <img :src='redManicule' class='maniculeImg' :style="{width: titleTextSize*0.6 + 'px', height: titleTextSize*0.6 + 'px', marginLeft: titleTextSize*-0.13 + 'px', marginBottom: titleTextSize*-0.11 + 'px'}">
             </span>
    </div>
    <Vue3Marquee :clone='true' direction="reverse" :duration="marqueeDuration" v-if="platforms !== undefined && platformLinks !== undefined">
        <template v-for="index in minimumCloneAmount" :key="index">
        <template v-for="(platformIndex, index) in platformIDs" :key=platformIndex>
            <a :href=platformLinks[index] target='_blank' class="platformLink" :style="{height: 0.125*holderWidth + 'px', width: 0.125*holderWidth + 'px', marginTop: 0.01*holderWidth + 'px', marginRight: 0.0125*holderWidth + 'px', marginBottom: 0.025*holderWidth + 'px', marginLeft: 0.0125*holderWidth + 'px'}">
                <img :src=platformImgsRed[platformIndex]>
            </a>
        </template>
        </template>
    </Vue3Marquee>
</template>

<script>
import { Vue3Marquee } from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'
//Import platformIcons
//Red
import webIconRed from '@/assets/PlatformIcons/WebIcon-Red.svg'
import windowsIconRed from '@/assets/PlatformIcons/WindowsIcon-Red.svg'
import macosIconRed from '@/assets/PlatformIcons/MacOSIcon-Red.svg'
import androidIconRed from '@/assets/PlatformIcons/AndroidIcon-Red.svg'
import iosIconRed from '@/assets/PlatformIcons/iOSIcon-Red.svg'
import xboxIconRed from '@/assets/PlatformIcons/XboxIcon-Red.svg'
import playstationIconRed from '@/assets/PlatformIcons/PlaystationIcon-Red.svg'
import switchIconRed from '@/assets/PlatformIcons/SwitchIcon-Red.svg'
import youtubeIconRed from '@/assets/PlatformIcons/YoutubeIcon-Red.svg'
import vimeoIconRed from '@/assets/PlatformIcons/VimeoIcon-Red.svg'
//white
import webIconWhite from '@/assets/PlatformIcons/WebIcon-White.svg'
import windowsIconWhite from '@/assets/PlatformIcons/WindowsIcon-White.svg'
import macosIconWhite from '@/assets/PlatformIcons/MacOSIcon-White.svg'
import androidIconWhite from '@/assets/PlatformIcons/AndroidIcon-White.svg'
import iosIconWhite from '@/assets/PlatformIcons/iOSIcon-White.svg'
import xboxIconWhite from '@/assets/PlatformIcons/XboxIcon-White.svg'
import playstationIconWhite from '@/assets/PlatformIcons/PlaystationIcon-White.svg'
import switchIconWhite from '@/assets/PlatformIcons/SwitchIcon-White.svg'
import youtubeIconWhite from '@/assets/PlatformIcons/YoutubeIcon-White.svg'
import vimeoIconWhite from '@/assets/PlatformIcons/VimeoIcon-White.svg'

//0 = web, 1 = windows, 2 = macos, 3 = android, 4 = ios, 5 = xbox, 6 = playstation, 7 = switch

//Manicules
import maniculeRed from '@/assets/WindowsDefaultManicule-red.svg'
import maniculeWhite from '@/assets/WindowsDefaultManicule-white.svg'

export default {
    name: 'ProjectPlatform',

    components: {
        Vue3Marquee,
    },

    props: {
        platforms: [String],
        platformLinks: [String],
        viewWidth: Number,
        viewHeight: Number,
        holderWidth: Number,
        titleTextSize: Number,
        resizePaddingWidth: Number,
    },

    data(){
        return{
            platformImgsRed: [webIconRed, windowsIconRed, macosIconRed, androidIconRed, iosIconRed, xboxIconRed, playstationIconRed, switchIconRed, youtubeIconRed, vimeoIconRed],
            platformImgsWhite: [webIconWhite, windowsIconWhite, macosIconWhite, androidIconWhite, iosIconWhite, xboxIconWhite, playstationIconWhite, switchIconWhite, youtubeIconWhite, vimeoIconWhite],
            availablePlatforms: ["web", "windows", 'macos', 'android', 'ios', 'xbox', 'playstation', 'switch', 'youtube', 'vimeo'],
            marqueeDuration: 0,
            duration: 6.5,
            platformIDs: [],
            minimumCloneAmount: 1,
            downArrows: ['ᐁ','↓','🠗','↧','⇂','⇓','⇟','⇣','⇩','⥥','⥕','➴','☟','⮯'],
            displayedDownArrow: '☟',
            index: 0,
            displaySeconds: 0.25,
            actionDisplay: 'Play On',

            redManicule: maniculeRed,
            whiteManicule: maniculeWhite,
        }
    },

    created(){
        //this.index = Math.floor(Math.random() * (this.downArrows.length-0.00000001));
        //this.displayedDownArrow = this.downArrows[this.index];
    },

    watch:{
        platforms: function(newVal, oldVal){

            this.marqueeDuration = 0;
            this.marqueeDuration += this.duration*newVal.length*this.minimumCloneAmount;

            var videoOnlyCheck = true;

            for(let i = 0; i < this.availablePlatforms.length; i++){
                if(this.platforms.includes(this.availablePlatforms[i])){
                    this.platformIDs.push(i);
                    if(i < 7){
                        videoOnlyCheck = false;
                    }
                }
            }

            if(videoOnlyCheck){
                this.actionDisplay = 'Watch';
            }else{
                this.actionDisplay = 'Play On';
            }
        }
    },

    mounted(){
        this.marqueeDuration = 0;
        this.marqueeDuration += this.duration*this.platforms.length*this.minimumCloneAmount;

        var videoOnlyCheck = true;

        for(let i = 0; i < this.availablePlatforms.length; i++){
            if(this.platforms.includes(this.availablePlatforms[i])){
                this.platformIDs.push(i);
                if(i < 7){
                    videoOnlyCheck = false;
                }
            }
        }

        if(videoOnlyCheck){
            this.actionDisplay = 'Watch'
        }else{
            this.actionDisplay = 'Play On';
        }

        /*
        this.timer = setInterval(() => {
            this.displayedDownArrow = this.downArrows[this.index];
            this.index++;
            if(this.index >= this.downArrows.length){
                this.index = 0;
            }
        }, this.displaySeconds*1000)
        */
    },
    /*
    beforeDestroy() {
        clearInterval(this.timer)
    },
    */
}
</script>

<style scoped>

#PlayTitleContainer{
    text-overflow: clip;
    align-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    width: 100%;

    height: 7.5vw;
}

span{
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;
    color: #E52C3A;
    padding: 0;

    font-size: 6vw;
    letter-spacing: 0.1vw;
    line-height: 7vw;
    margin: 0 -150vw 0 -150vw;
}

span .flip{
    display: inline-block;
    font-style: normal;
    transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

.platformLink{
    background-color: #E52C3A;
    align-self: center;

    height: 12.5vw;
    width: 12.5vw;
    margin: 1vw 1.25vw 2.5vw 1.25vw;
}

a img{
    height: 100%;
    width: 100%;
    margin: 0%;
}

a:hover img{
    margin: 7.5%;
    height: 85%;
    width: 85%;
}

</style>