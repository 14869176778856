<template>
    <p :style="{fontSize: 0.25*titleTextSize + 'px', lineHeight: 0.33*titleTextSize + 'px'}">
        <img src='@/assets/RedSquare.svg' :style="{width: 0.2*titleTextSize + 'px', height: 0.2*titleTextSize + 'px', marginLeft: 0.2*titleTextSize + 'px', marginRight: 0.2*titleTextSize + 'px', marginBottom: -0.01*titleTextSize + 'px'}">I'm currently inspired<br>by <em>{{displayedInspiration}}</em>.
        <img src='@/assets/RedSquare.svg' :style="{width: 0.2*titleTextSize + 'px', height: 0.2*titleTextSize + 'px', marginLeft: 0.2*titleTextSize + 'px', marginRight: 0.2*titleTextSize + 'px', marginBottom: -0.01*titleTextSize + 'px'}">
    </p>
</template>

<script>
export default {
name: 'LandingInspire',

props:{
    inspirations: [String],
    viewWidth: Number,
    viewHeight: Number,
    holderWidth: Number,
    titleTextSize: Number,
    resizePaddingWidth: Number,
},

data(){
    return{
        displayedInspiration: "something",
        displaySeconds: 10,
        timer: null,
        index: 0,
    }
},

watch:{
    inspirations: function(newVal, oldVal){
        this.index = Math.floor(Math.random() * newVal.length - 0.0000001);
        this.displayedInspiration = newVal[this.index];
        this.index++;
            if(this.index >= newVal.length){
        this.index = 0;
    }
    }
},

created(){
    this.index = Math.floor(Math.random() * this.inspirations.length - 0.0000001);
    this.displayedInspiration = this.inspirations[this.index];
    this.index++;
    if(this.index >= this.inspirations.length){
        this.index = 0;
    }
},

mounted: function () {
  this.timer = setInterval(() => {
    this.displayedInspiration = this.inspirations[this.index];
    this.index++;
    if(this.index >= this.inspirations.length){
        this.index = 0;
    }
    console.log(this.displayedInspiration);
  }, this.displaySeconds*1000)
},

beforeDestroy() {
  clearInterval(this.timer)
},

}
</script>

<style scoped>
p{
    align-self: center;
    color: #1F1F1C;
    text-align: left;
    letter-spacing: initial;
    width: 85%;

    padding: 0% 5% 0% 10%; /* Randomise first value between right value and (idk what the max is)*/
    margin: auto 0;
}

p img{
    padding: 0 0 0 0;
    display: inline-block;
}

p em{
    color: #E52C3A;
}
</style>