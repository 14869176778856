<template>
    <Vue3Marquee :clone='true' :duration="marqueeDuration" v-if="tagline !== undefined">
      <template v-for="index in minimumCloneAmount" :key="index">
        <span :style="{fontSize: 0.5*titleTextSize + 'px'}">&nbsp;{{seperatorString}}&nbsp;{{ tagline }}</span>
      </template>
    </Vue3Marquee>
</template>

<script>
import { Vue3Marquee } from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'

export default {
  name: 'ProjectTagline',

  components: {
    Vue3Marquee,
  },

  props:{
    tagline: String,
    viewWidth: Number,
    viewHeight: Number,
    holderWidth: Number,
    titleTextSize: Number,
    resizePaddingWidth: Number,
  },

  data(){
    return{
      seperatorString: '¤',
      marqueeDuration: 0,
      duration: 0.4,
      minimumCloneAmount: 1,
    }
  },

  watch:{
    tagline: function(newVal, oldVal){
      this.marqueeDuration = 0;
      this.marqueeDuration += this.duration*newVal.length*this.minimumCloneAmount;
    }
  },

  mounted(){
    this.marqueeDuration = 0;
    this.marqueeDuration += this.duration*this.tagline.length*this.minimumCloneAmount;
  }
}

</script>

<style scoped>

span{
    font-size: 4.5vw;
    font-weight: 300;
    text-transform: uppercase;
    color: #E52C3A;
    letter-spacing: 0vw;
}

</style>