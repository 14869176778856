<template>
    <span class='roleTitle' :style="{fontSize: 0.25*titleTextSize + 'px', letterSpacing: 0.03*titleTextSize + 'px', height: 0.03*holderWidth + 'px'}">{{roleTitle}}</span>
    <div :style="{height: 0.27*holderWidth + 'px'}">
        <ul v-if="roles !== undefined">
            <li v-for="role in roles" :key="role" :style="{fontSize: 0.18*titleTextSize + 'px', letterSpacing: 0.01*titleTextSize + 'px', marginLeft: 0.15*titleTextSize + 'px', marginRight: 0.15*titleTextSize + 'px', marginTop: -0.063*holderWidth + 'px'}">
                <img src='@/assets/WhiteSquare.png' :style="{width: 0.15*titleTextSize + 'px', height: 0.15*titleTextSize + 'px', marginRight: -0.015*titleTextSize + 'px', marginBottom: 0.65*titleTextSize + 'px'}">{{ role }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ProjectRoles',

    props:{
        roles: [String],
        viewWidth: Number,
        viewHeight: Number,
        holderWidth: Number,
        titleTextSize: Number,
        resizePaddingWidth: Number,
    },

    data(){
        return{
            roleTitle: 'my role'
        }
    },

    created(){
        if(this.roles.length !== 1){
            this.roleTitle = 'my roles';
        }
    }
}
</script>

<style scoped>

.roleTitle{
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    color: #FFFFFF;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    z-index: 50; 
    align-self: top;
    width: 100%;
}

div{
    display: flex;
    flex-flow: column nowrap;
    align-self: top;
    width: 100%;
    flex-shrink: 0;
}

ul{
    align-self: center;
    height: 100%;
    white-space: nowrap;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    list-style: none;
    writing-mode: vertical-lr;
    flex-shrink: 0;
}

li{
    color: #FFFFFF;
    text-transform: uppercase;
    font-weight: 500;
    text-align: left;
    padding: 0 0 0 0;
}

li img{
    padding: 0 0 0 0;
}

</style>