<template>
    <div id="burger" :class="{ 'active' : isBurgerActive }" @click.prevent="toggle">
        <slot>
            <button type="button" class="burger-button" :style="{width: 0.65*titleTextSize + 'px', height: 0.65*titleTextSize + 'px', margin: 0.15*titleTextSize + 'px'}" title="Menu">
                <span class="burger-bar burger-bar--1" :style="{
                    right: 0.15*titleTextSize + 'px', 
                    left: 0.15*titleTextSize + 'px', 
                    height: 0.05*titleTextSize + 'px', 
                    marginTop: -0.03*titleTextSize + 'px',
                    webkitTransform: 'translateY(' + -0.125*titleTextSize + 'px)',
                    transform: 'translateY(' + -0.125*titleTextSize + 'px)'}">       
                </span>
                <span class="burger-bar burger-bar--2" :style="{
                    right: 0.15*titleTextSize + 'px', 
                    left: 0.15*titleTextSize + 'px', 
                    height: 0.05*titleTextSize + 'px', 
                    marginTop: -0.03*titleTextSize + 'px'}">
                </span>
                <span class="burger-bar burger-bar--3" :style="{
                    right: 0.15*titleTextSize + 'px', 
                    left: 0.15*titleTextSize + 'px', 
                    height: 0.05*titleTextSize + 'px', 
                    marginTop: -0.03*titleTextSize + 'px',
                    webkitTransform: 'translateY(' + 0.13*titleTextSize + 'px)',
                    transform: 'translateY(' + 0.13*titleTextSize + 'px)'}">
                </span>
            </button>
        </slot>
    </div>
</template>

<script>
    export default {
        name: 'MenuButton',

        props:{
            viewWidth: Number,
            viewHeight: Number,
            holderWidth: Number,
            titleTextSize: Number,
            resizePaddingWidth: Number,
        },

        computed:{
            isBurgerActive(){
                return this.$store.state.isNavOpen;
            }
        },

        methods: {
            toggle() {
                this.$store.commit('toggle');
            }
        }
    }
</script>

<style>
   .hidden {
        visibility: hidden;
    }

    button {
        cursor: pointer;
    }

    /* remove blue outline */
    button:focus {
        outline: 0;
    }

    .burger-button {
        position: fixed;
        display: block;
        z-index: 800;
        border: 0;
        border-radius: 0;
        background-color: #E52C3A;
        pointer-events: all;
        transition: transform .6s cubic-bezier(.165,.84,.44,1);
    }

    .burger-bar {
        background-color: #FFFFFF;
        position: absolute;
        top: 50%;
        width: auto;
        transition: transform .6s cubic-bezier(.165,.84,.44,1),opacity .3s cubic-bezier(.165,.84,.44,1),background-color .6s cubic-bezier(.165,.84,.44,1);
    }

    .burger-bar--2 {
        transform-origin: 0% 50%;
        transform: scaleX(.7);
    }

    .burger-button:hover .burger-bar--2 {
        transform: scaleX(1);
    }

    .no-touchevents .burger-bar--2:hover {
        transform: scaleX(1);
    }

    #burger.active .burger-button {
        transform: rotate(-180deg);
    }

    #burger.active .burger-bar {
        background-color: #FFFFFF;
    }

    #burger.active .burger-bar--1 {
        transform: rotate(45deg) !important;
    }

    #burger.active .burger-bar--2 {
        opacity: 0;
    }

    #burger.active .burger-bar--3 {
        transform: rotate(-45deg)  !important;
    }
</style>