<template>
    <p :style="{fontSize: 0.5*titleTextSize + 'px', lineHeight: 0.66*titleTextSize + 'px'}">
        <img src='@/assets/RedSquare.svg' :style="{
        width: 0.4*titleTextSize + 'px', 
        height: 0.4*titleTextSize + 'px', 
        marginLeft: 0.4*titleTextSize + 'px', 
        marginRight: 0.4*titleTextSize + 'px', 
        marginBottom: -0.02*titleTextSize + 'px'}">I make playful experiences that make use of inventive and disruptive interaction to help players work towards a better understanding of <em>{{ displayedPerson }}</em><br> unique way of thinking.
        <img src='@/assets/RedSquare.svg' :style="{
        width: 0.4*titleTextSize + 'px', 
        height: 0.4*titleTextSize + 'px', 
        marginLeft: 0.4*titleTextSize + 'px', 
        marginRight: 0.4*titleTextSize + 'px', 
        marginBottom: -0.02*titleTextSize + 'px'}">
    </p>
</template>

<script>

export default {
    name: 'LandingDescription',

    data() {
        return{
            person: ['my', 'your', 'our', 'someone’s', 'everyone’s', 'their own', 'a group’s'],
            displayedPerson: 'someone’s',
            timer: null,
            index: 0,
            displaySecondsMin: 0.5,
            displaySecondsMax: 2,
        }
    },

    props:{
        viewWidth: Number,
        viewHeight: Number,
        holderWidth: Number,
        titleTextSize: Number,
        resizePaddingWidth: Number,
    },

    created(){
        this.index = Math.floor(Math.random() * (this.person.length-0.00000001));
        this.displayedPerson = this.person[this.index];
    },

    mounted: function () {
    this.timer = setInterval(() => {
            this.displayedPerson = this.person[this.index];
            this.index++;
            if(this.index >= this.person.length){
                this.index = 0;
            }
            console.log(this.displayedPerson);
        }, ((Math.random() * (this.displaySecondsMax - this.displaySecondsMin)) + this.displaySecondsMin)*1000)
    },

    beforeDestroy() {
        clearInterval(this.timer)
    },

}
</script>

<style scoped>

p{
    color: #1F1F1C;
    background-color: #FFFFFF;
    text-align: left;
    letter-spacing: initial;

    padding: 5% 8% 5% 16%;
    margin: 0;
    align-self: center;
}

p img{
    padding: 0 0 0 0;
    display: inline-block;
}

p em{
    color: #E52C3A;
}
</style>