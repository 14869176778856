<template>
<div id='landing' class='baseHolder' ref='land'>
    <div class='resizePadding MainHolder' :style="{width: resizePaddingWidth + 'px'}">
    </div>

    <div id='HolderLeft' class='MainHolder' :style="{width: holderWidth*0.44 + 'px'}">
        <div id='ShowcaseHolder' :style="{width: viewWidth + 'px', marginLeft: -resizePaddingWidth + 'px', height: holderWidth*0.44 + 'px', marginTop: 0.9*holderWidth + 'px'}">
            <LandingShowcase :previewIDs="projectIDs" :previewURLs="projectPreviewURL" :previewTypes="projectPreviewType" :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
        </div>
    </div>

    <div id='HolderMiddle' class='MainHolder' :style="{width: holderWidth*0.19 + 'px'}">
        <div :style="{height: holderWidth*(0.2) + 'px', marginTop: holderWidth*0.05 + 'px'}">
            <a href="#About" :style="{fontSize: 0.25*titleTextSize + 'px', lineHeight: 0.4*titleTextSize + 'px'}"><img :src='whiteManicule' class='manicule flip' :style="{width: 1.5*titleTextSize + 'px', height: 1.5*titleTextSize + 'px'}"><br>about</a>
        </div>

        <div id='ShortTextHolder' :style="{height: holderWidth*0.51 + 'px', width: holderWidth + 'px', marginLeft: -0.44*holderWidth + 'px'}">         
            <LandingDescription :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
        </div>


        <div :style="{height: holderWidth*(0.25) + 'px', marginTop: holderWidth*0.23 + 'px'}">
            <a :href="'#' + projectIDs[0]" :style="{fontSize: 0.25*titleTextSize + 'px', lineHeight: 0.6*titleTextSize + 'px'}">projects<br><img :src='whiteManicule' class='manicule' :style="{width: 1.5*titleTextSize + 'px', height: 1.5*titleTextSize + 'px'}"></a>
        </div>
    </div>

    <div id='HolderRight' class='MainHolder' :style="{width: holderWidth*0.37 + 'px'}">
        <!--
        <div id='InspirationHolder' :style="{height: (0.95)*viewHeight - 1.31*holderWidth + 'px', width: holderWidth*0.37 + 'px', marginTop: (0.44+0.60)*holderWidth + 'px', marginLeft: displacementFactor*resizePaddingWidth*0.3 + 'px'}" v-if="inspirations[0] !== undefined">
            <LandingInspire :inspirations="inspirations" :view-width="viewWidth" :view-height="viewHeight" :holder-width="holderWidth" :title-text-size="titleTextSize" :resize-padding-width="resizePaddingWidth"/>
        </div>
        -->
    </div>

    <div class='resizePadding MainHolder' :style="{width: resizePaddingWidth + 'px'}">
    </div>
</div>
</template>

<script>
//Manicules
import maniculeRed from '@/assets/WindowsDefaultManicule-red.svg'
import maniculeWhite from '@/assets/WindowsDefaultManicule-white.svg'

import LandingDescription from '@/components/LandingComponents/LandingDescription.vue';
import LandingShowcase from '@/components/LandingComponents/LandingShowcase.vue';
import LandingInspire from '@/components/LandingComponents/LandingInspire.vue';

export default {
    name: 'LandingPage',
    components:{
        LandingDescription,
        LandingShowcase,
        LandingInspire,
    },

    data(){
        return{
            projectPreviewURL: [],
            projectPreviewType: [],
            projectIDs: [],
            displacementFactor: 0,

            redManicule: maniculeRed,
            whiteManicule: maniculeWhite,
        }
    },

    props:{
        projects: [Object],
        inspirations: [String],
        viewWidth: Number,
        viewHeight: Number,
        holderWidth: Number,
        titleTextSize: Number,
        resizePaddingWidth: Number,
    },

    watch:{
        projects: function(newVal, oldVal){
            this.projectPreviewURL.length = 0;
            this.projectPreviewType.length = 0;

            for(let i = 0; i < newVal.length; i++){
                this.projectPreviewURL.push(newVal[i].landingPreview.fields.file.url);
                this.projectPreviewType.push(newVal[i].landingPreview.fields.file.contentType);
                this.projectIDs.push(newVal[i].title.replaceAll(/\s/g,''));
            }
        }
    },

    created(){
        for(let i = 0; i < this.projects.length; i++){
            this.projectPreviewURL.push(this.projects[i].landingPreview.fields.file.url);
            this.projectPreviewType.push(this.projects[i].landingPreview.fields.file.contentType);
            this.projectIDs.push(this.projects[i].title.replaceAll(/\s/g,''));
        }
        console.log(this.inspirations);
        this.displacementFactor = Math.random();
        this.displacementFactor = 0;
    },

    mounted(){
        if(!window.location.href.includes('#')){
            const element = document.getElementById("Home");
            element.scrollIntoView();
        }
        else{
            const element = document.getElementById(window.location.href.split('#')[1]);
            element.scrollIntoView();
        }       
    }
}
</script>

<style scoped>

.baseHolder{
    display: flex;
    flex-flow: row;
}

.MainHolder{
    margin: 0;
    padding: 0;
    height: 95vh;
    flex-shrink: 0;
}

.resizePadding{
    flex-shrink: 0;
}

#HolderMiddle{
    background-color: #E52C3A;
    z-index: 80;
}

#HolderMiddle div{
    padding: 0 0 0 0;
    margin: 0 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

#HolderMiddle a{
    color: #FFFFFF;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    align-self: center;
}

#HolderMiddle a .manicule{
    display: inline-block;
}

#HolderMiddle a .flip{
    transform: scale(-1, -1);
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
}

#ShortTextHolder{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

}

#InspirationHolder{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
}

</style>