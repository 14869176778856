<template>
    <div id='DateContainer' v-if="publicationMonth !== undefined && runtime !== undefined" :style="{marginLeft: 0.01*holderWidth + 0.05*resizePaddingWidth + 'px', marginTop: 0.01*holderWidth + 'px', width: 0.35*holderWidth + 0.9*resizePaddingWidth + 'px'}">
        <div v-for="(month, index) in months" :key="month" :style="{width: 0.025*holderWidth + 'px'}">
            <span class='month' :style="{ color: index === publicationMonth ? activeColor : passiveColor, fontSize: titleTextSize/3 + 'px', lineHeight: (titleTextSize/3)*0.8 + 'px', letterSpacing: 0.02*titleTextSize + 'px'}">{{ month.name }}</span>
            <span v-if="index === publicationMonth" class="runtime" :style="{fontSize: 0.2*titleTextSize + 'px', lineHeight: 0.2*titleTextSize + 'px', textAlign: index < 6 ? 'left' : 'right', marginBottom: 0.05*titleTextSize + 'px', marginLeft: index < 6 ? (0.05*titleTextSize + 'px') : (-2.2*titleTextSize + 'px')}">
                project runtime<br><em class='time'>{{runtime}}</em>
            </span>
        </div>
    </div>
</template>

<script>
export default {
name: 'ProjectDate',

props:{
    publicationMonth: Number,
    runtime: String,
    viewWidth: Number,
    viewHeight: Number,
    holderWidth: Number,
    titleTextSize: Number,
    resizePaddingWidth: Number,
},

data(){
    return{
        months: [
            {name: 'January', highlight: false},
            {name: 'February', highlight: false},
            {name: 'March', highlight: false},
            {name: 'April', highlight: false},
            {name: 'May', highlight: false},
            {name: 'June', highlight: false},
            {name: 'July', highlight: false},
            {name: 'August', highlight: false},
            {name: 'September', highlight: false},
            {name: 'October', highlight: false},
            {name: 'Novemeber', highlight: false},
            {name: 'December', highlight: false}
            ],
        activeColor: '#E52C3A',
        passiveColor: '#FBECED',
    }
},

created(){

}

}
</script>

<style scoped>

#DateContainer{
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
}

#DateContainer div{
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
}

.month{
    font-weight: 700;
    writing-mode: vertical-rl;
    text-orientation: upright;
    text-transform: uppercase;
    z-index: 0;
}

.runtime{
    position: relative;
    color: #1F1F1C;
    text-transform: uppercase;
    font-weight: 300;
    white-space: nowrap;
    z-index: 50;
    align-self: flex-end;
}

.time{
    font-weight: 500;
    font-style: italic;
}

</style>