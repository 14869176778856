<template>
  <Vue3Marquee :clone='true' :duration="marqueeDuration" v-if="collaboratorName !== undefined && collaboratorUrl !== undefined">
    <template v-for="index in minimumCloneAmount" :key="index">
      <template v-for="(collaborator, index) in collaboratorName" :key=collaborator>
        <span class='marqueeSpan' :style="{fontSize: 0.2*titleTextSize + 'px', height: 0.03*holderWidth + 'px', lineHeight: 0.03*holderWidth + 'px'}">{{ seperatorString }}<a :href=collaboratorUrl[index] target="_blank">{{ collaborator }}</a></span>
      </template>
    </template>
  </Vue3Marquee>

  <Vue3Marquee :clone='true' :duration="marqueeDuration" v-else>
    <template v-for="index in minimumCloneAmount" :key="index">
      <span class='marqueeSpan' :style="{fontSize: 0.2*titleTextSize + 'px', height: 0.03*holderWidth + 'px', lineHeight: 0.03*holderWidth + 'px'}">{{ seperatorString }}Solo Project</span>
    </template>
  </Vue3Marquee>

  <span id='Collaborators' :style="{fontSize: 0.25*titleTextSize + 'px', letterSpacing: 0.03*titleTextSize + 'px', marginRight: -0.199*holderWidth + 'px', height: 0.03*holderWidth + 'px', marginTop: 0.1*titleTextSize + 'px'}">
    <img src='@/assets/RedSquare.png' :style="{
      height: 0.2*titleTextSize + 'px', 
      width: 0.2*titleTextSize + 'px', 
      marginLeft: 0.25*titleTextSize + 'px', 
      marginRight: 0.35*titleTextSize + 'px', 
      marginBottom: -0.006*titleTextSize + 'px'}">collaborators
    <img class='half' src='@/assets/LeftRed.png' :style="{
      height: 0.2*titleTextSize + 'px', 
      width: 0.2*titleTextSize + 'px', 
      marginLeft: 0.25*titleTextSize + 'px', 
      marginRight: 0.172*holderWidth + 'px', 
      marginBottom: -0.006*titleTextSize + 'px'}">
    <img class='half' src='@/assets/RightRed.png' :style="{
      height: 0.2*titleTextSize + 'px', 
      width: 0.2*titleTextSize + 'px', 
      marginLeft: 0*titleTextSize + 'px', 
      marginRight: 0*titleTextSize + 'px', 
      marginBottom: -0.006*titleTextSize + 'px'}">
  </span>

  <Vue3Marquee :clone='true' direction='reverse' :duration="marqueeDuration" v-if="collaboratorName !== undefined && collaboratorUrl !== undefined">
    <template v-for="index in minimumCloneAmount" :key="index">
      <template v-for="(collaborator, index) in collaboratorName" :key=collaborator>
        <span class='marqueeSpan' :style="{fontSize: 0.2*titleTextSize + 'px', height: 0.03*holderWidth + 'px', lineHeight: 0.03*holderWidth + 'px'}">{{ seperatorString }}<a :href=collaboratorUrl[index] target="_blank">{{ collaborator }}</a></span>
      </template>
    </template>
  </Vue3Marquee>

  <Vue3Marquee :clone='true' direction='reverse' :duration="marqueeDuration" v-else>
    <template v-for="index in minimumCloneAmount" :key="index">
      <span class='marqueeSpan' :style="{fontSize: 0.2*titleTextSize + 'px', height: 0.03*holderWidth + 'px', lineHeight: 0.03*holderWidth + 'px'}">{{ seperatorString }}Solo Project</span>
    </template>
  </Vue3Marquee>

</template>

<script>
import { Vue3Marquee } from 'vue3-marquee';

export default {
  name: 'ProjectCollaborators',

  components: {
    Vue3Marquee
  },

  data(){
    return{
      seperatorString: '・',
      marqueeDuration: 0,
      duration: 0.3, 
      minimumCloneAmount: 1,
    }
  },

  props: {
    collaboratorName: [String],
    collaboratorUrl: [String],
    viewWidth: Number,
    viewHeight: Number,
    holderWidth: Number,
    titleTextSize: Number,
    resizePaddingWidth: Number,
  },

  watch:{
    collaboratorName: function(newVal, oldVal){
      this.marqueeDuration = 0;
      for(let i = 0; i < newVal.length; i++){
        this.marqueeDuration += this.duration*newVal.length*this.minimumCloneAmount;
      }
    }
  },

  mounted(){
    this.marqueeDuration = 0;
    for(let i = 0; i < this.collaboratorName.length; i++){
      this.marqueeDuration += this.duration*this.collaboratorName[i].length*this.minimumCloneAmount;
    }
  }
}
</script>

<style scoped>

#Collaborators{
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  color: #E52C3A;
  text-transform: uppercase;
  font-weight: 300;
  text-align: right;
  z-index: 50;
}

#Collaborators img{
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  display: inline-block;
  flex-shrink: 0;
}

.marqueeSpan{
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  color: #E52C3A;
  font-weight: 300;
}

a{
  color: #E52C3A;
  text-decoration: none;
}

a:hover{
  color: #FFFFFF;
  background-color: #E52C3A;
  text-decoration: none;
}

</style>