<template>
    <Vue3Marquee direction="reverse" :duration="marqueeDuration" v-if="previewURLs !== undefined">
        <template v-for="index in minimumCloneAmount" :key="index">
            <a :href="'#' + previewIDs[index]" v-for="(url, index) in previewURLs" :key="url">
            <video autoplay loop muted v-if="previewTypes[index].includes('video')" class="content" :style="{width: 0.44*holderWidth + 'px', height: 0.44*holderWidth + 'px', marginLeft: (0.44/6)*holderWidth + 'px', marginRight: (0.44/6)*holderWidth + 'px'}">
                <source :src="url" :type="previewTypes[index]">
                your browser does not support video preview...
            </video>
            <img :src="url" v-if="previewTypes[index].includes('image')" class="content" :style="{width: 0.44*holderWidth + 'px', height: 0.44*holderWidth + 'px', marginLeft: (0.44/6)*holderWidth + 'px', marginRight: (0.44/6)*holderWidth + 'px'}">
            </a>
        </template>
    </Vue3Marquee>
</template>

<script>
import { Vue3Marquee } from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'

export default {
    name: 'LandingShowcase',

    components: {
        Vue3Marquee,
    },

    props:{
        previewIDs: [String],
        previewURLs: [String],
        previewTypes: [String],
        viewWidth: Number,
        viewHeight: Number,
        holderWidth: Number,
        titleTextSize: Number,
        resizePaddingWidth: Number,
    },

    data(){
        return{
            marqueeDuration: 0,
            duration: 10,
            minimumCloneAmount: 1,
        }
    },

    watch:{
        previewURLs: function(newVal, oldVal){
            this.marqueeDuration = 0;
            this.marqueeDuration += this.duration*newVal.length*this.minimumCloneAmount;
        }
    },

    mounted(){
        this.marqueeDuration = 0;
        this.marqueeDuration += this.duration*this.previewURLs.length*this.minimumCloneAmount;
    }
}
</script>

<style scoped>

video{
    object-fit: cover;
}

img{
    object-fit: cover;
}

</style>