<template>
<div id='BaseHolder'>
  <div class='resizePadding MainHolder' :style="{width: resizePaddingWidth + 'px'}">
  </div>

  <div id='HolderLeft' class='MainHolder' :style="{width: holderWidth*0.44 + 'px'}">
    <span :style="{fontSize: titleTextSize*0.25 + 'px', lineHeight: titleTextSize*0.275 + 'px'}">⛔⛔⛔⛔ STOP ⛔⛔⛔⛔<br><br>There is definetely nothing down here</span>
  </div>

  <div id='HolderMiddle' class='MainHolder' :style="{width: holderWidth*0.19 + 'px'}">
  </div>

  <div id='HolderRight' class='MainHolder' :style="{width: holderWidth*0.37 + 'px'}">
    <span :style="{fontSize: titleTextSize*0.25 + 'px', lineHeight: titleTextSize*0.275 + 'px'}"><!-- For the person that found this in the html code: I'm glad you found this, a bit less fun this way, but still commendable :) -->Well hello there likeminded curious user!<br>👋👋👋👋👋👋👋👋<br>Send me a message saying 'codeword: 2319'</span>
    
  </div>

  <div class='resizePadding MainHolder' :style="{width: resizePaddingWidth + 'px'}">
  </div>
</div>
</template>

<script>
export default {
    name: 'ProjectSingle',
    components:{

    },

    data(){
        return{

        }
    },

    props:{
        project: Object,
        viewWidth: Number,
        viewHeight: Number,
        holderWidth: Number,
        titleTextSize: Number,
        resizePaddingWidth: Number,
    },
}
</script>

<style scoped>

#BaseHolder{
    display: flex;
    flex-flow: row;
}

.MainHolder{
    margin: 0;
    padding: 0;
    height: 90vh;
    flex-shrink: 0;
}

.resizePadding{
    flex-shrink: 0;
}

#HolderMiddle{
    background-color: #E52C3A;
}

#HolderLeft{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

#HolderLeft span{
    margin: 0 10%;
    align-self: center;
}

#HolderRight{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
}

#HolderRight span{
    margin: 20% 10%;
    align-self: flex-end;
}

</style>