<template v-if="previewFiles !== undefined">
    <div class='flexHelper' :style="{maxWidth: holderWidth*0.37 + (0.3)*resizePaddingWidth + 'px'}">
    <div id="contentContainer" v-for="(file, index) in previewFiles" :key="file" :style="{}">
        <video autoplay loop muted v-if="file.fields.file.contentType.includes('video')" class="content" :style="{maxHeight: ((1.735-0.235-0.35)*holderWidth - 0.2*viewHeight)/previewFiles.length - (0.4*titleTextSize*(previewFiles.length-1))/previewFiles.length + 'px'}">
            <source :src="file.fields.file.url" :type="file.fields.file.contentType">
            your browser does not support video preview...
        </video>
        <img :src="file.fields.file.url" v-if="file.fields.file.contentType.includes('image')" class="content" :style="{maxHeight: ((1.735-0.235-0.35)*holderWidth - 0.2*viewHeight)/previewFiles.length - (0.4*titleTextSize*(previewFiles.length-1))/previewFiles.length + 'px'}">
        <img src='@/assets/RedSquare.svg' class="squareSeperator" v-if="index !== previewFiles.length - 1" :style="{width: 0.2*titleTextSize + 'px', height: 0.2*titleTextSize + 'px', margin: 0.1*titleTextSize + 'px'}">
    </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectNuancedPreview',

    props: {
        previewFiles: [Object],
        viewWidth: Number,
        viewHeight: Number,
        holderWidth: Number,
        titleTextSize: Number,
        resizePaddingWidth: Number,
    },

    data(){
        return{

        }
    }
}
</script>

<style scoped>
.flexHelper{
    align-self: flex-start;
    display: flex;
    flex-flow: column nowrap;
    flex-shrink: 0;
    height: 100%;
}

#contentContainer{
    flex-shrink: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.content{
    width: 100%;
    height: auto;
    object-fit:contain;
    margin: 0;
    padding: 0;
    align-self: center;
}

.squareSeperator{
    align-self: center;
}
</style>