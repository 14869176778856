import { createApp } from 'vue'
import { createStore } from 'vuex'
import Vue3Marquee from 'vue3-marquee'
import VueMeta from 'vue-meta'
import 'vue3-marquee/dist/style.css'
import App from './App.vue'

const store = createStore({

  state(){
    return{
        isNavOpen: false
    }
  },
  
  mutations: {
    toggle(state) {
        state.isNavOpen = !state.isNavOpen;
    }

  }
})


createApp(App).use(store).use(Vue3Marquee).use(VueMeta).mount('#app')
